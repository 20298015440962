<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_amortization" class="w-100" :title="CodeInvoice(account_receivable.type_invoice) +' '+ account_receivable.serie+'-'+ account_receivable.number + ' | Registro de cobros '">
      <b-form id="Form" @submit.prevent="Validate">
        <b-row>
          <b-col md="3">
            <b-form-group label="Medio de Pago :">
              <b-form-select v-model="account_charges.payment_method" :options="payment_method"></b-form-select>
              <small v-if="errors.payment_method" class="form-text text-danger" >Seleccione un metodo</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Documento :">
              <b-form-input v-model="account_charges.document"></b-form-input>
              <small v-if="errors.document" class="form-text text-danger" >Ingrese un documento</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Fecha de emisión:">
              <b-form-input type="date" v-model="account_charges.broadcast_date"></b-form-input>
              <small v-if="errors.broadcast_date" class="form-text text-danger" >Seleccione un fecha</small>
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group label="Banco :">
              <b-form-select  v-model="account_charges.bank" :options="bank"></b-form-select>
              <small v-if="errors.bank" class="form-text text-danger">Ingrese un banco</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Caja:">
              <b-form-input disabled v-model="account_charges.box" ></b-form-input>
              <small v-if="errors.id_box" class="form-text text-danger">Necesita abrir una caja</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Moneda:">
              <b-form-select disabled v-model="account_charges.coin" :options="coins" ></b-form-select>
              <small v-if="errors.coin" class="form-text text-danger">Seleccione una moneda</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="T. Cambio:">
              <b-form-input type="number" class="text-right" step="any" v-model="account_charges.exchange_rate" ></b-form-input>
              <small v-if="errors.exchange_rate" class="form-text text-danger">Seleccione un tipo de cambio</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="total:">
              <b-form-input type="number" class="text-right" step="any" v-model="account_charges.total" ></b-form-input>
              <small v-if="errors.total" class="form-text text-danger">Ingrese un monto</small>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Observación:">
              <b-form-input v-model="account_charges.observation" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="1">
            <b-form-group label=".">
              <b-button class="form-control" v-if="account_charges.id_account_charge.length == 0" type="submit" variant="primary" title="Guardar"><i class="fas fa-save"></i></b-button>
              <b-button class="form-control" v-if="account_charges.id_account_charge.length != 0" type="submit" variant="primary" title="Modificar"><i class="fas fa-edit"></i></b-button>
            </b-form-group>
          </b-col>
 
        </b-row>
      </b-form>
      <b-row>
      <b-col md="12">
        <div class="table-responsive mt-3 height-table">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th width="3%" class="text-center">#</th>
                <th width="9%" class="text-center">Fecha</th>
                <th width="25%" class="text-center">Medio de Pago</th>
                <th width="10%" class="text-center">Documento</th>
                <th width="33%" class="text-center">Banco</th>
                <th width="8%" class="text-center">Total</th>
                <th width="7%" class="text-center">Estado</th>
                <th width="5%" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody v-for="(item, it) in payments" :key="it">
              <tr>
                <td class="text-center">{{ it + 1 }}</td>
                <td class="text-center">{{ item.broadcast_date }}</td>
                <td class="text-left">{{ NameMethodPayment(item.payment_method) }}</td>
                <td class="text-left">{{ item.document }}</td>
                <td class="text-left">{{ NameBank(item.bank) }}</td>
                <td class="text-right">{{ item.total }}</td>
                <td class="text-center">
                  <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                  <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                </td>
                <td class="text-center">
                    <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item  @click="ViewCharge(item.id_account_charge)">Editar</b-dropdown-item>
                        <b-dropdown-item  @click="ConfirmDeleteAccountCharge(item.id_account_charge)" >Eliminar</b-dropdown-item >
                      </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>

      <b-col md="5">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="30%" class="text-center">F. Emision</th>
                  <th width="30%" class="text-center">F. Vencimiento</th>
                  <th width="10%" class="text-center">Moneda</th>
                  <th width="15%" class="text-center">Total</th>
                  <th width="10%" class="text-center">Deuda</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{ account_receivable.broadcast_date }}</td>
                  <td class="text-center">{{ account_receivable.expiration_date }}</td>
                  <td class="text-center">{{ account_receivable.coin }}</td>
                  <td class="text-right">{{ account_receivable.total }}</td>
                  <td class="text-right">{{ account_receivable.balance }}</td>
             
                </tr>
              </tbody>
            </table>
          </div>
      </b-col>

      </b-row>
      

      
      
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading'
var moment = require("moment");
export default {
  name: "ModalsProduct",
  components:{
    LoadingComponent,
  },
  data() {
    return {
        isLoading: false,
        modal_amortization:false,
        module:'AccountReceivable',
        role:3,
        id_account_receivable:0,
        payments: [],
        account_receivable:{
          type_invoice:'',
          serie:'',
          number:'',
          coin:'',
          total:'',
          fee:'',
          balance:'',
        },
        account_charges: {
          id_account_charge:'',
          id_account_receivable:'',
          id_client:'',
          id_user:'',
          id_box:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_method:'008',
          document:'',
          box:'',
          coin:'PEN',
          exchange_rate:'1.00',
          bank:'',
          number_op:'',
          observation:'',
          total: (0).toFixed(2),
          state:1,
      },
      payment_method: [
          {value :"008", text :'EFECTIVO'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"005", text :'TARJETA'},
          // {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"222", text :'YAPE'},
          {value :"333", text :'PLIN'},
      ],
      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],
      bank:[
        {value :'', text:'Ninguno'},
        {value :'001', text:'BANCO CENTRAL DE RESERVA DEL PERU'},
        {value :'002', text:'BANCO DE CREDITO DEL PERU'},
        {value :'003', text:'BANCO INTERNACIONAL DEL PERU'},
        {value :'005', text:'BANCO LATINO'},
        {value :'007', text:'BANCO CITIBANK N.A.'},
        {value :'008', text:'BANCO STANDARD CHARTERED'},
        {value :'009', text:'BCO.SCOTIABANK PERU SAA (ANTES WIESE SUDAMERIS)'},
        {value :'011', text:'BANCO CONTINENTAL'},
        {value :'018', text:'BANCO DE LA NACION'},
        {value :'023', text:'BANCO COMERCIO'},
        {value :'026', text:'BANCO NORBANK'},
        {value :'037', text:'BANCO DEL PROGRESO'},
        {value :'038', text:'BANCO INTERAMERICANO DE FINANZAS'},
        {value :'041', text:'BANCO SUDAMERICANO'},
        {value :'043', text:'BANCO DEL TRABAJO'},
        {value :'044', text:'BANCO SOLVENTA'},
        {value :'045', text:'BANCO SERBANCO'},
        {value :'046', text:'BANK BOSTON N.A. SUCURSAL DEL PERU'},
        {value :'047', text:'ORION CORPORACION DE CREDITO'},
        {value :'048', text:'BANCO NUEVO PAIS'},
        {value :'049', text:'MIBANCO'},
        {value :'050', text:'BANQUE NATIONALE DE PARIS - ANDES S.A.'},
        {value :'053', text:'BANCO HSBC'},
        {value :'056', text:'BANCO SANTANDER PERU S.A.'},
        {value :'071', text:'CORPORACION FINANCIERA DE DESARROLLO - COFIDE'},
        {value :'083', text:'SOLUCION - FINANCIERA DE CREDITO DEL PERU'},
        {value :'086', text:'FINANDAEWOO S.A.'},
        {value :'087', text:'FINANCIERA C.M.R.'},
        {value :'088', text:'VOLVO FINANCE PERU'},
        {value :'089', text:'FINANCIERA CORDILLERA S.A.'},
        {value :'091', text:'GENERALI PERU CIA. SEGUROS'},
        {value :'092', text:'LA VITALICIA'},
        {value :'093', text:'REASEGURADORA PERUANA'},
        {value :'094', text:'SEGUROS LA FENIX PERUANA'},
        {value :'095', text:'SECREX  CIA. SEGUROS'},
        {value :'099', text:'OTROS'},
      ],
    
        errors:{
          broadcast_date:false,
          payment_method:false,
          amount:false,
          interest:false,
          total:false,
          number_op:false,
          bank:false,
        }

     
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalChargesShow', (id_account_receivable) => {
      this.account_charges.id_account_charge = '';
      this.account_charges.id_account_receivable = '';
      this.account_charges.id_client = '';
      this.account_charges.id_user = '';
      this.account_charges.id_box = '';
      this.account_charges.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
      this.account_charges.payment_method = '008';
      this.account_charges.document = '';
      this.account_charges.box = '';
      this.account_charges.coin = 'PEN';
      this.account_charges.exchange_rate = '1.00';
      this.account_charges.bank = '';
      this.account_charges.number_op = '';
      this.account_charges.observation = '';
      this.account_charges.total =  (0).toFixed(2);
      this.account_charges.state = 1;

      this.modal_amortization = true;
      this.id_account_receivable = id_account_receivable;
      this.ViewAccountReceivable();
      
      this.LisAccountCharges();

    });
    
  },
  methods: {
      ViewAccountReceivable,
      LisAccountCharges,
      Validate,
      AddAccountCharge,
      EditAccountCharge,
      CodeInvoice,
      NameMethodPayment,
      NameBank,
      ViewCharge,
      ConfirmDeleteAccountCharge,
      DeleteCharge,

      GetBoxByUser,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function GetBoxByUser() {
  let me = this;
  let url = me.url_base + "box/get-box-by-user/"+me.user.id_user;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.account_charges.box = response.data.result.code + " | " + response.data.result.user;
      me.account_charges.id_box = response.data.result.id_box;
    } else {
      me.account_charges.id_box = '';
      me.account_charges.box = '';
    }
  })
  .catch((error) => {
  });
}

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function NameMethodPayment(code) {
  return CodeToName.NameMethodPayment(code);
}

function NameBank(code) {
  return CodeToName.NameBank(code);
}


function ViewAccountReceivable() {
  let me = this;
  let url = me.url_base + "account-receivable/view/"+me.id_account_receivable;
  me.isLoading = true;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.account_receivable.broadcast_date = response.data.result.broadcast_date;
      me.account_receivable.expiration_date = response.data.result.expiration_date;
      me.account_receivable.type_invoice = response.data.result.type_invoice;
      me.account_receivable.serie = response.data.result.serie;
      me.account_receivable.number = response.data.result.number;
      me.account_receivable.coin = response.data.result.coin;
      me.account_receivable.total = response.data.result.total;
      me.account_receivable.paid = response.data.result.paid;
      me.account_receivable.balance = response.data.result.balance;

      me.account_charges.id_client = response.data.result.id_client;
      me.account_charges.total = parseFloat(me.account_receivable.balance);
      me.account_charges.total = me.account_charges.total.toFixed(2);
      me.account_charges.coin = response.data.result.coin;
      me.GetBoxByUser();
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    me.isLoading = false;
    console.log(error);
  });
}

function Validate() {
  let me = this;
  this.errors.broadcast_date = this.account_charges.broadcast_date.length == 0 ? true : false;
  this.errors.payment_method = this.account_charges.payment_method.length == 0 ? true : false;
  this.errors.id_box = this.account_charges.id_box.length == 0 ? true : false;
  this.errors.total = this.account_charges.total.length == 0 ? true : false;
  if (this.account_charges.payment_method != '008') {
    this.errors.number_op = this.account_charges.number_op.length == 0 ? true : false;
    this.errors.bank = this.account_charges.bank.length == 0 ? true : false;
  }else{
     this.errors.number_op = false;
    this.errors.bank = false;
  }
  
  if (this.errors.b1roadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.payment_method == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_box == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.total == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.number_op == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }if (this.errors.b1roadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.bank == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }if (this.errors.b1roadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  if (this.account_charges.id_account_charge.length == 0) {
      Swal.fire({
        title: 'Esta seguro de registrar el cobro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Estoy de Acuerdo!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.AddAccountCharge();
        }
      })
  }else{
      Swal.fire({
        title: 'Esta seguro de modificar el cobro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Estoy de Acuerdo!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.EditAccountCharge();
        }
      })
  }





 
}


function AddAccountCharge() {
    let me = this;

    let url = me.url_base + "account-receivable/add-charge";
    me.account_charges.id_user = me.user.id_user;
    me.account_charges.id_account_receivable = me.id_account_receivable;
    let data = me.account_charges;
    axios({
      method: "POST",
      url:url,
      data:data,
      headers: { token: me.token, module: me.module, role: me.role },
    })
    .then(function (response) {
      if (response.data.status == 201) {
          me.account_charges.id_account_charge = '';
          me.account_charges.id_account_receivable = '';
          me.account_charges.id_client = '';
          me.account_charges.id_user = '';
          me.account_charges.id_box = '';
          me.account_charges.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
          me.account_charges.payment_method = '008';
          me.account_charges.document = '';
          me.account_charges.box = '';
          me.account_charges.coin = 'PEN';
          me.account_charges.exchange_rate = '1.00';
          me.account_charges.bank = '';
          me.account_charges.number_op = '';
          me.account_charges.observation = '';
          me.account_charges.total =  (0).toFixed(2);
          me.account_charges.state = 1;
        me.ViewAccountReceivable();
        me.LisAccountCharges();
        EventBus.$emit('RefreshAccountReceivable');
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function EditAccountCharge() {
  let me = this;
  let url = me.url_base + "account-receivable/edit-charge";
  let data = me.account_charges;
  axios({
    method: "PUT",
    url:url,
    data:data,
    headers: { token: me.token, module: me.module, role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.account_charges.id_account_charge = '';
      me.account_charges.id_account_receivable = '';
      me.account_charges.id_client = '';
      me.account_charges.id_user = '';
      me.account_charges.id_box = '';
      me.account_charges.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
      me.account_charges.payment_method = '008';
      me.account_charges.document = '';
      me.account_charges.box = '';
      me.account_charges.coin = 'PEN';
      me.account_charges.exchange_rate = '1.00';
      me.account_charges.bank = '';
      me.account_charges.number_op = '';
      me.account_charges.observation = '';
      me.account_charges.total =  (0).toFixed(2);
      me.account_charges.state = 1;
      me.ViewAccountReceivable();
      me.LisAccountCharges();
      EventBus.$emit('RefreshAccountReceivable');
      Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
    } else {
      Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
    }
  })
  .catch((error) => {
    console.log(error);
  });
}



function ViewCharge(id_account_charge) {
  let me = this;
  let url = me.url_base + "account-receivable/view-charge/"+id_account_charge;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.account_charges.id_account_charge = response.data.result.id_account_charge;
          me.account_charges.id_account_receivable = response.data.result.id_account_receivable;
          me.account_charges.id_client = response.data.result.id_client;
          me.account_charges.id_user = response.data.result.id_user;
          me.account_charges.id_box = response.data.result.id_box;
          me.account_charges.broadcast_date = response.data.result.broadcast_date;
          me.account_charges.payment_method = response.data.result.payment_method;
          me.account_charges.document = response.data.result.document;
          me.account_charges.number_op = response.data.result.number_op;
          me.account_charges.exchange_rate = response.data.result.exchange_rate;
          me.account_charges.bank = response.data.result.bank;
          me.account_charges.observation = response.data.result.observation;
          me.account_charges.total = response.data.result.total;
          me.account_charges.state = response.data.result.state;
          me.account_charges.box = response.data.result.box_code+" | "+response.data.result.box_user;
      } 
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ConfirmDeleteAccountCharge(id_account_charge) {
  Swal.fire({
    title: "Esta seguro de eliminar el pago?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCharge(id_account_charge);
    }
  });
}

function DeleteCharge(id_account_charge) {
  let me = this;
  let url = me.url_base + "account-receivable/delete-charge/"+id_account_charge;
  axios({
    method: "delete",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.ViewAccountReceivable();
        me.LisAccountCharges();
        EventBus.$emit('RefreshAccountReceivable');
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}


//Buscar productos
function LisAccountCharges() {
  let me = this;
  let url = me.url_base + "account-receivable/list-charge/"+me.id_account_receivable;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payments = response.data.result;
      } else {
        me.payments = [];
      }
      me.isLoading = false;
    })
    .catch((error) => {
      me.isLoading = false;
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}
</script>
